import { Suspense, Fragment } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { wrapUseRoutes } from '@sentry/react';
import defaultRoutes from './routes';
import maintenanceRoutes from '../../features/maintenance/router/routes';


const useSentryRoutes = wrapUseRoutes(useRoutes);

function Router() {
  let routes: RouteObject[] = [];

  if ('true'.localeCompare(import.meta.env.VITE_MAINTENANCE_MODE) === 0) {
    routes = [...maintenanceRoutes];
  } else {
    routes = defaultRoutes;
  }

  return <Suspense fallback={<Fragment />}>{useSentryRoutes(routes)}</Suspense>;
}

export default Router;
