import { lazy } from 'react';
import { BASE_URL } from './uri';

const Validate = lazy(() => import('../pages/ValidatePage'));

const routes = [
  {
    path: `${BASE_URL}/:session/:guest`,
    element: <Validate />,
  },
];

export default routes;
